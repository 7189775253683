@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply rounded-full px-10 py-5 hover:text-white font-medium;
  }
}

body,
html,
* {
  transition: 0.3s all ease-in-out;
}

/* .banner {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./assets/HeadBanner.webp);
  background-position: top;
  background-size: cover;
} */

.gradient {
  background: linear-gradient(to right, #c6c600 2%, #ff8040 82%) !important;
}
